html {
  scroll-behavior: smooth;
}

h2 {
  text-align: center;
  font-size: 2.5em;
}

h2.title {
  color: #3B2314;
  margin-bottom: 2px;
}

span.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

#social-links
{
  margin: 10px 0;
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
}

#social-links img {
  margin: 0 5px;
}

.f-raleway {
  font-family: 'Raleway', sans-serif;
}

.fs-16 {
  font-size: 16px;
}

.card__box {
  transform: scale(1);
  transition: transform 350ms ease-in-out;
}

.card__box:focus,
.card__box:hover {
  transform: scale(1.125);
  transition: transform 350ms ease-in-out;
}